import React from 'react'
import { Marker } from '@react-google-maps/api';
import { LatLng } from './services/types'

export interface GeoMarkerProps {
    position: LatLng;
    title?: string;
    color?: string;
    size?: number;
}

export const GeoMarker: React.FunctionComponent<GeoMarkerProps> = (props) => {
    return (
        <Marker
            icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: props.color || 'green',
                fillOpacity: 1,
                scale: props.size || 8,
                strokeColor: '',
                strokeWeight: 0,
            }}
            position={props.position}
            title={props.title}
        />
    )
}