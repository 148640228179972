import * as React from "react";
import JsxParser from "@recursive-robot/react-jsx-parser";
import { useCanvasBindings } from "../../../shared/providers/CanvasBindingsProvider";
import { useJsxParserComponents } from "../../../shared/providers/JsxParserComponentsProvider";

const blacklistedAttrs = [];
const blacklistedTags = [];
const renderError = ({ error }) => <span className="error-message">{`JSX error: ${error}`}</span>;

export interface JsxRendererProps {
    template: string;
    data?: any;
}

function _JsxRenderer(props: JsxRendererProps) {
    const { template, data, ...rest } = props;
    const _bindings = useCanvasBindings();
    const components = useJsxParserComponents();

    const defineFunction = React.useCallback((...args) => {
        return new Function(...args).bind({ ..._bindings, ...data }); // eslint-disable-line no-new-func
    }, [_bindings]);

    const bindings = { ..._bindings, ...data, defineFunction };

    return (
        <JsxParser
            bindings={bindings}
            components={components}
            jsx={template}
            showWarnings={false}
            disableKeyGeneration={true}
            blacklistedAttrs={blacklistedAttrs}
            blacklistedTags={blacklistedTags}
            renderError={renderError}
            renderInWrapper={false}
            key={template} // Force re-parse when template content is updated
            {...rest}
        />
    );
}

const JsxRenderer = React.memo(_JsxRenderer);

export { JsxRenderer }; 