import create from "zustand";

interface LoaderStore {
    isLoading: boolean;
    show: () => void;
    hide: () => void;
}

export const useLoaderStore = create<LoaderStore>(setState => ({
    isLoading: false,

    show: () => {
        setState(_ => ({
            isLoading: true
        }));
    },

    hide: () => {
        setState(_ => ({
            isLoading: false
        }));
    }
}));