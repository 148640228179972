import _ from "lodash";
import React from "react";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { structuralEqualityExcludingTransientEntries } from "../../../shared/utilities";

const _Memo = ({ children }) => children;

// Memoize the children based on the input prop values, excluding functions and DOM elements from the equality checks...
const Memo = React.memo(_Memo, (prevProps, nextProps) => structuralEqualityExcludingTransientEntries(prevProps, nextProps));

(Memo as DocumentedComponent).metadata = {
    description: `The \`Memo\` component provides a convenient mechanism for memoizing (caching) the components contained within it, based on arbitrary user-defined values.

If all the input prop values remain the same between render cycles, rendering of the children will short-circuit and the cached content will be retained.

This can be useful for performance optimization in certain scenarios, such as when rendering large amounts of read-only items (\`Chart\`, \`Table\`, \`Calendar\`, \`DataGrid\`, \`Gantt\`, etc.).
`,
    attributes: []
};

export { Memo };