import React from 'react';
import "./LoadingScreen.scss";
import loading from "../../assets/loading.svg";
import logo from "../../assets/logo.png";

export default function LoadingScreen(props: any) {
    return (
        <div className="loading-screen">
            <img className="logo" alt="StoryChart Logo" src={logo} />
            <img className="loading" alt="Loading Indicator" src={loading} />
        </div>
    );
}