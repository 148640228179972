import create from "zustand";

interface DialogStateModel {
    isOpen: boolean;
    title: string;
    component: JSX.Element | null;
}

interface DialogStore {
    dialog: DialogStateModel;
    show: (title: string, component: JSX.Element) => void;
    hide: () => void;
}

export const useDialogStore = create<DialogStore>(setState => ({
    dialog: { isOpen: false, title: "", component: null },

    show: (title: string, component: JSX.Element) => {
        setState(state => ({
            dialog: {
                ...state.dialog,
                isOpen: true,
                title,
                component
            }
        }));
    },

    hide: () => {
        setState(state=> ({
            dialog: {
                ...state.dialog,
                isOpen: false,
                title: "",
                component: null
            }
        }));
    }
}));