import React from 'react';
import "./TextField.scss";
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { TextField as BaseTextField } from "../../../shared/components";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    separator?: string;
    trimWhitespace?: boolean;
}

function _TextField(props: Props) {
    const { storyline, updateParameterValue, name, separator, trimWhitespace, ...other } = props;

    const [lastBoundValue, setLastBoundValue] = React.useState(null);

    const getValueFromText = (text: string) => separator ?
        _.chain(_.split(text, separator)).map(s => trimWhitespace ? _.trim(s) : s).filter(a => !!a).value() :
        text;

    const getTextFromValue = (value: any) => separator ?
        _.join(value, trimWhitespace ? separator + " " : separator) :
        value;

    const [value, setValue] = React.useState(() => {
        const newValue = storyline.parameterValues?.get(name);
        setLastBoundValue(newValue);
        return getTextFromValue(newValue);
    });

    const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
        const newText = (event.target as HTMLInputElement).value;
        setValue(newText);

        if (!(event.nativeEvent instanceof UIEvent)) {
            updateParameterValue(name, getValueFromText(newText));
        }
    }

    const handleBlur = (event: any) => {
        const newText = event.target.value;
        updateParameterValue(name, getValueFromText(newText));
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            const newText = getTextFromValue(newValue);

            if (lastBoundValue !== newValue) {
                setValue(newText);
                setLastBoundValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return (
        <BaseTextField
            name={name}
            fullWidth
            className="textfield"
            variant="outlined"
            {...other}
            value={value}
            onInput={handleInput}
            onBlur={handleBlur}
        />
    );
}

const TextField = connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(_TextField);

(TextField as DocumentedComponent).metadata = {
    description: "The TextField component allows the user to enter arbitrary text for storage in a variable.  This control supports both single-value and multi-value input (via the use of a customizable delimiter character).",
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the parameter that the value(s) will be read from and persisted to." },
        { name: `separator`, type: `string`, description: "The character or sequence of characters that will be used to separate multiple values.  In most cases this would be a comma (`,`) or semicolon (`;`).  If no separator is specified, the text field is a single-value input and the text entered is bound directly to the variable." },
        { name: `trimWhitespace`, type: `boolean`, description: "If `true`, whitespace characters are removed from the beginning and end of the values obtained by separating the string along the specified `separator` characters.  E.g., `\"apple, banana\"` would return `[\"apple\", \"banana\"]` if `true` and `[\"apple\", \" banana\"]` if this value is `false`." },
    ]
};

export default TextField;