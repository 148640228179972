import React from "react";
import {
    Routes,
    Route
} from "react-router-dom";
import HeadlessVdt from "./HeadlessVdt";

export default function Admin(props: unknown) {
    return (
        <Routes>
            <Route path="headless-vdt" element={<HeadlessVdt />} />
        </Routes>
    );
}