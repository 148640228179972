import "./PhoneNumberInput.scss";
import { MuiTelInput, MuiTelInputCountry, MuiTelInputInfo, matchIsValidTel } from "mui-tel-input";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

type PhoneNumberInputProps = {
    key?: string;
    name: string;
    validationErrorParameterName?: string;
    validationErrorText?: string;
    value?: string;
    defaultCountry?: MuiTelInputCountry;
    onChange?: (value: string, isValid: boolean) => boolean;
    onBlur?: (value, isvalid) => boolean;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
    const {
        key,
        name,
        validationErrorParameterName,
        validationErrorText = "Invalid phone number.",
        value: _value,
        defaultCountry = "ZA",
        onChange: _onChange,
        onBlur: _onBlur,
        ...rest
    } = props;

    const parameterValue = useSelector((state: RootState) => state.storyline.parameterValues.get(name));
    const [value, setValue] = useState<string>(name ? (parameterValue ?? "") : _value);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        if (!parameterValue)
            return;

        setValue(parameterValue);
        validateAndPersistInput(parameterValue);
    }, [parameterValue]);

    const dispatch = useThunkDispatch();

    const validateAndPersistInput = (value: string) => {
        const isValid = matchIsValidTel(value);
        if (_onBlur && _onBlur(value, isValid) === false)
            return;

        const validationError = isValid ? undefined : validationErrorText;

        setErrorMessage(validationError);
        if (validationErrorParameterName)
            dispatch(updateParameterValue(validationErrorParameterName, validationError));

        dispatch(updateParameterValue(name, value)); 
    };

    const onBlur = useCallback(() => {
        if (!name)
            return;

        validateAndPersistInput(value);
    }, [dispatch, value, validationErrorText]);

    const onChange = useCallback((value: string, info: MuiTelInputInfo) => {
        const isValid = matchIsValidTel(value);

        if (_onChange && _onChange(value, isValid) === false)
            return;

        setValue(value);

        // User has changed the country...
        if (info.reason == "country") {
            validateAndPersistInput(value);
        }
    }, [setValue, onBlur]);

    return (
        <MuiTelInput
            key={key}
            name={name}
            fullWidth
            size="small"
            defaultCountry={defaultCountry}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errorMessage}
            helperText={errorMessage}
            {...rest}
        />
    );
};

(PhoneNumberInput as DocumentedComponent).metadata = {
    description: "The `PhoneNumberInput` component provides a convenient mechanism for entering international phone numbers, with built-in validation logic.  This component is a wrapper around the [mui-tel-input](https://viclafouch.github.io/mui-tel-input/docs/api-reference/) component, with added support for exposing the entered value to a parameter.",
    attributes: [
        { name: "name", type: "string", description: "The name of the parameter that the phone number will be read from and persisted to." },
        { name: "validationErrorParameterName", type: "string", description: "The name of the parameter which the validation error message will be written to. Useful for consolidating validation states in a central location." },
        { name: "validationErrorText", type: "string", description: "A custom validation error message to display when the phone number is invalid. Optional - defaults to 'Invalid phone number.'." },
        { name: "value", type: "string", description: "The initial value of the phone number input. Only applies if `name` is not provided." },
        { name: "defaultCountry", type: "string", description: "The default country to use for the phone number input. Uses the [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) format. Optional - defaults to 'ZA'." },
        { name: "onChange", type: "function", template: "onChange={(value, isValid) => {$1}}", description: "A callback function that will be called whenever the phone number input changes. If a `false` value is returned by the function, execution of the default change handler is short-circuited." },
        { name: "onBlur", type: "function", template: "onBlur={(value, isValid) => {$1}}", description: "A callback function that will be called whenever the phone number input loses focus. If a `false` value is returned by the function, execution of the default blur handler is short-circuited." }
    ],
    isSelfClosing: true,
};
 
export { PhoneNumberInput };