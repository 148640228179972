interface UserPreferences {
    slideTransitionAnimationStyle: "none" | "slide" | "flip";
    voice: {
        languageCode: string,
        name: string
    }
}

var userPreferences: UserPreferences = null;

export function setUserPreferences(preferences: UserPreferences) {
    userPreferences = preferences;
    localStorage.setItem("user-preferences", JSON.stringify(userPreferences));
}

export function getUserPreferences(): UserPreferences {
    if (userPreferences) return userPreferences;

    userPreferences = loadUserPreferencesFromLocalStorage();

    return userPreferences;
}

function loadUserPreferencesFromLocalStorage(): UserPreferences {
    const savedJson = localStorage.getItem("user-preferences");
    if (savedJson) {
        try {
            const savedObject = JSON.parse(savedJson);
            if (!savedObject.voice) {
                savedObject.voice = {
                    languageCode: "en-GB",
                    name: "en-GB-Wavenet-A"
                };
            }
            return savedObject;
        }
        catch (ex) {
            // Invalid preferences stored - return the default values...
            console.log(ex);
        }
    }

    return {
        slideTransitionAnimationStyle: "slide",
        voice: {
            languageCode: "en-GB",
            name: "en-GB-Wavenet-A"
        }
    };
}