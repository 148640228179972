import React from "react";
import { logger } from "../services/loggingService";

interface AppInsightsViewTrackerProps {
    componentType: string,
    title: string,
    metadata?: any,
    children: any
}

export default function AppInsightsViewTracker(props: AppInsightsViewTrackerProps) {
    const { componentType, title, metadata, children } = props;

    React.useEffect(() => {
        const recordPageViewStart = () => {
            logger.startTrackPage(title);
        }
        
        const recordPageViewEnd = () => {
            logger.stopTrackPage(title, window.location.href, { componentType: componentType, windowWidth: window?.innerWidth, windowHeight: window?.innerHeight, ...metadata });
        }

        // Stop tracking the page/component view when the document becomes hidden and resume/restart tracking once it becomes visible.
        // See https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event for documentation on when the document is considered hidden.
        const onDocumentVisibilityChanged = () => {
            switch (document.visibilityState) {
                case "hidden":
                    recordPageViewEnd();
                    logger.flush(false);
                    break;
                case "visible":
                    recordPageViewStart();
                    break;
            }
        }

        recordPageViewStart();
        document.addEventListener("visibilitychange", onDocumentVisibilityChanged);

        return () => {
            recordPageViewEnd();
            document.removeEventListener("visibilitychange", onDocumentVisibilityChanged);
        };
    }, []);

    return children;
}