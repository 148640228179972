import { NotificationsState, NotificationActionTypes, ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, INCREMENT_OUTSTANDING_REQUEST_COUNT, DECREMENT_OUTSTANDING_REQUEST_COUNT } from "./types";

const initialState: NotificationsState = {
    notifications: [],
    outstandingRequestCount: 0
}

export function notificationsReducer(
    state = initialState,
    action: NotificationActionTypes
): NotificationsState {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.notification.key,
                        ...action.notification,
                    },
                ],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        case INCREMENT_OUTSTANDING_REQUEST_COUNT:
            return {
                ...state,
                outstandingRequestCount: state.outstandingRequestCount + 1
            }

        case DECREMENT_OUTSTANDING_REQUEST_COUNT:
            return {
                ...state,
                outstandingRequestCount: Math.max(state.outstandingRequestCount - 1, 0)
            }

        default:
            return state;
    }
}