import * as React from "react";
import { Cell, CellTemplate, Compatible, Span, Uncertain } from "@silevis/reactgrid";
import { Tooltip } from "../Tooltip";

export interface HeaderCell extends Cell, Span {
    type: "header";
    text?: React.ReactNode; // @deprecated - use `content` instead.
    content?: React.ReactNode;
    tooltip?: React.ReactNode;
}

export class HeaderCellTemplate implements CellTemplate<HeaderCell> {
    getCompatibleCell(uncertainCell: Uncertain<HeaderCell>): Compatible<HeaderCell> {
        const content = uncertainCell.content ?? uncertainCell.text ?? null;
        const tooltip = uncertainCell.tooltip ?? null;
        const text = "";
        const value = NaN;
        return { ...uncertainCell, content, text, value, tooltip };
    }

    render(cell: Compatible<HeaderCell>): React.ReactNode {
        return cell.tooltip ?
            <Tooltip arrow title={cell.tooltip}>
                {cell.content as JSX.Element}
            </Tooltip> :
            cell.content;
    }

    isFocusable = (_cell: Compatible<HeaderCell>): boolean => false;

    getClassName(cell: Compatible<HeaderCell>, _isInEditMode: boolean): string {
        return cell.className ?? "";
    }
}