import React, { useState } from "react";
import { Alert, AlertProps, Collapse, Paper, IconButton } from "@mui/material";
import { Close, ExpandMore, ExpandLess } from "@mui/icons-material";
import { useSnackbar } from 'notistack';
import { CircleCheck, CircleSlash, Alert as AlertIcon, Info } from "./icons";
import { DocumentedComponent } from "./DocumentedComponent";

const SuccessAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="success" icon={<CircleCheck />} {...props} />);

(SuccessAlert as DocumentedComponent).metadata = {
    description: `Displays a success alert.  This is a preconfigured Alert with a green background and a checkmark icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

const ErrorAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="error" icon={<CircleSlash />} {...props} />);

(ErrorAlert as DocumentedComponent).metadata = {
    description: `Displays an error alert.  This is a preconfigured Alert with a red background and a cross icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

const WarningAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="warning" icon={<AlertIcon />} {...props} />);

(WarningAlert as DocumentedComponent).metadata = {
    description: `Displays a warning alert.  This is a preconfigured Alert with a yellow background and an exclamation mark icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

const InfoAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) => <Alert ref={ref} severity="info" icon={<Info />} {...props} />);

(InfoAlert as DocumentedComponent).metadata = {
    description: `Displays an info alert.  This is a preconfigured Alert with a blue background and an information icon.`,
    attributes: [
        { name: "action", description: "The action to display in the alert.  Optional.", type: "object" }
    ]
};

type DetailedAlertProps = AlertProps & {
    details?: string;
    message?: string;
};

const DetailedErrorAlert = React.forwardRef<any, DetailedAlertProps>((props: DetailedAlertProps, ref) => {
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);
    const details = props.details || props.message;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(props.id);
    };

    return (
        <div className="col-fill">
            <Paper ref={ref} className="MuiAlert-root MuiAlert-standard MuiAlert-standardError" elevation={0} role="alert">
                <div className="MuiAlert-icon">
                    <CircleSlash />
                </div>
                <div className="MuiAlert-message">
                    {props.message}
                </div>
                <div className="MuiAlert-action">
                    <IconButton
                        size="small"
                        color="inherit"
                        aria-label="Show more"
                        onClick={handleExpandClick}
                    >
                        {
                            expanded ?
                                <ExpandLess fontSize="small" /> :
                                <ExpandMore fontSize="small" />
                        }
                    </IconButton>
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={handleDismiss}>
                        <Close fontSize="small" />
                    </IconButton>
                </div>
            </Paper>
            <Collapse in={expanded} timeout="auto" unmountOnExit className="detailed-error-text">
                {
                    details?.indexOf?.("<html") > 0 ?
                        <div className="html-error markdown" dangerouslySetInnerHTML={{ __html: details }} /> :
                        <pre>{details}</pre>
                }
            </Collapse>
        </div>
    );
});

export { SuccessAlert, ErrorAlert, WarningAlert, InfoAlert, DetailedErrorAlert };