import "./BusinessMap.scss";
import { Figure } from "react-plotly.js";
import Plotly, { PlotDatum, PlotMouseEvent } from "plotly.js";
import { MouseEventHandler } from "react";
import { Fab } from "../../../shared/components";
import { Tooltip } from "../Tooltip";
import Chart from "../Chart";
import _ from "lodash";
import React from "react";
import * as colors from "../../../shared/colors";
import { TreeChart } from "../../../shared/components/icons";
import { DEFAULT_CHART_LAYOUT } from "../../../shared/constants";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

type MouseOverItem = {
    icon: JSX.Element,
    tooltipContent?: any,
    onClick: MouseEventHandler
};

type ScatterlineWithHoverSupport = Partial<Plotly.ScatterLine> & {
    hover_color?: string;
};

type Plot = Plotly.PlotData & {
    color?: string,
    display?: Plotly.Dash,
    data: { [x: string]: any },
    is_hidden?: boolean
};

type ConeOfProbability = {
    boundaries: {
        lower: { [x: string]: any },
        upper: { [x: string]: any },
    },
    sediments: { [plotName: string]: Plot },
    lines: { [plotName: string]: Plot }
};

type BusinessMapInput = {
    lines: { [plotName: string]: Plot },
    sediments: { [plotName: string]: Plot },
    current_date?: string | Date | undefined,
    cone_of_probability: ConeOfProbability
};

type BusinessMapProps = {
    input: BusinessMapInput,
    layout?: Partial<Plotly.Layout>,
    onKpiDashboardClick?: (date: any) => void,
    getMouseOverItems?: (currentPoint: PlotDatum, sharedPoints: PlotDatum[]) => MouseOverItem[],
};

/// Sediment plots require each series to have values for each coordinate.
/// This function forward-fills the series data, if absent.
function forwardFillSedimentSeriesData(sedimentInputs: { [plotName: string]: Plot; }) {
    Object.entries(sedimentInputs).reduce((prev, [_key, { data: curr }]) => {
        // Fill all null/empty values with 0 and return the initial series...
        if (prev == null) {
            Object.keys(curr).forEach(key => {
                curr[key] = curr[key] ?? 0;
            });
            return curr;
        }
        // Data field is missing from current series - skip it...
        if (curr == null) {
            return prev;
        }

        // Forward-fill all missing/empty data values from the previous series...
        Object.keys(prev)
            .filter(key => curr?.[key] == null)
            .forEach(key => {
                curr[key] = prev[key] ?? 0;
            });

        // Yield current entry...
        return curr;
    }, null as object);
}

function BusinessMap(props: BusinessMapProps) {
    const { input, layout = {}, onKpiDashboardClick, getMouseOverItems, ...rest } = props;
    const plotlyInput = React.useMemo(() => {
        if (!input) {
            return { data: [], layout: null };
        }

        const data: Plotly.Data[] = [];

        const addLinePlot = ([title, plot]: [string, Plot]) => {
            data.push({
                name: title,
                line: {
                    color: plot.is_hidden ? "transparent" : plot.color,
                    hover_color: plot.is_hidden ? plot.color : undefined,
                    dash: plot.display,
                } as ScatterlineWithHoverSupport,
                showlegend: !plot.is_hidden,
                mode: "lines",
                type: "scatter",
                x: Object.keys(plot.data ?? {}),
                y: Object.values(plot.data ?? {}),
                ...plot
            });
        };

        const addSedimentPlot = ([title, plot]: [string, Plot]) => {
            data.push({
                name: title,
                line: {
                    // Hide the lines to prevent bleed-through of successors with no values...
                    color: "transparent"
                },
                fill: "tonexty",
                fillcolor: plot.color,
                mode: "lines",
                type: "scatter",
                x: Object.keys(plot.data ?? {}),
                y: Object.values(plot.data ?? {}),
                ...plot
            });
        }

        // Add stand-alone plots...
        const sedimentInputs = _.cloneDeep(input.sediments ?? {});
        forwardFillSedimentSeriesData(sedimentInputs);
        Object.entries(sedimentInputs).forEach(addSedimentPlot);
        Object.entries(input.lines ?? {}).forEach(addLinePlot);

        // Add cone of probability...
        data.push({
            name: "Probability Upper Bound",
            line: {
                color: colors.blue_2
            },
            mode: "lines",
            type: "scatter",
            x: Object.keys(input.cone_of_probability?.boundaries?.upper ?? {}),
            y: Object.values(input.cone_of_probability?.boundaries?.upper ?? {})
        });
        data.push({
            name: "Probability Lower Bound",
            line: {
                color: colors.blue_2
            },
            fill: "tonexty",
            fillcolor: colors.blue_3,
            mode: "lines",
            type: "scatter",
            x: Object.keys(input.cone_of_probability?.boundaries?.lower ?? {}),
            y: Object.values(input.cone_of_probability?.boundaries?.lower ?? {})
        });

        // Add CoP plots...
        Object.entries(input.cone_of_probability?.sediments ?? {}).forEach(addSedimentPlot);
        Object.entries(input.cone_of_probability?.lines ?? {}).forEach(addLinePlot);

        const mappedLayout = _.merge(_.clone(DEFAULT_CHART_LAYOUT), layout);
        mappedLayout.shapes = mappedLayout.shapes ?? [];

        // Add "Current Date" marker line...
        input?.current_date && mappedLayout.shapes.push({
            type: "line",
            x0: input.current_date,
            y0: 0,
            x1: input.current_date,
            y1: 1,
            yref: "paper",
            line: {
                color: colors.red_1,
                width: 2,
                dash: "dot"
            }
        });

        return {
            data: data,
            layout: mappedLayout
        };
    }, [input, layout]);

    const getTooltipContent = (e: PlotMouseEvent) => {
        const point = e.points[0];
        // Find all points from other traces that have the same x value as this one...
        const points = plotlyInput.data
            .flatMap((trace, traceIndex) =>
                _.zip(trace.x as Plotly.Datum[], trace.y as Plotly.Datum[])
                    .map(([x, y], pointIndex) => ({
                        curveNumber: traceIndex,
                        data: trace,
                        customdata: trace?.customdata?.at?.(pointIndex),
                        pointIndex: point.pointIndex,
                        pointNumber: point.pointNumber,
                        x: x,
                        xaxis: point.xaxis,
                        y: y,
                        yaxis: point.yaxis
                    } as PlotDatum))
                    .filter(p => p.x === point.x)
            );

        const mouseOverItems = getMouseOverItems?.(point, points) ?? [];

        return (
            <div className="business-map-tooltip-container">
                <div className="row">
                    {
                        mouseOverItems?.map?.(moi =>
                            <Tooltip title={moi.tooltipContent} PopperProps={{ placement: "top" }} arrow>
                                <Fab size="medium" onClick={moi.onClick}>
                                    {moi.icon}
                                </Fab>
                            </Tooltip>
                        )
                    }
                </div>

                <div className="row">
                    <Tooltip title="View KPI Dashboard" arrow>
                        <Fab size="medium" color="primary" onClick={() => onKpiDashboardClick?.(point.x)}>
                            <TreeChart size="small" color="white" />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
        );
    }

    const onHover = (e: PlotMouseEvent) => {
        // Get midpoints for all hover points...
        const midpoints = e.points.map(p => ({
            x: (p["bbox"].x0 + p["bbox"].x1) / 2,
            y: (p["bbox"].y0 + p["bbox"].y1) / 2,
            point: p
        }));

        // Return nearest point...
        const point = _.chain(midpoints)
            .sortBy(p => Math.abs(e.event.offsetX - p.x) + Math.abs(e.event.offsetY - p.y))
            .map(p => p.point)
            .first()
            .value();

        // If hover_color is defined, update color accordingly...
        if ((point.data.line as ScatterlineWithHoverSupport)?.hover_color) {
            point.data.line.color = (point.data.line as ScatterlineWithHoverSupport).hover_color;
        }

        return true;
    };

    const onUnhover = (e: PlotMouseEvent) => {
        // Change color back to "transparent", if applicable...
        (e?.points ?? []).forEach(p => {
            const hoverColor = (p.data.line as ScatterlineWithHoverSupport)?.hover_color;
            if (hoverColor) {
                p.data.line.color = "transparent";
            }
        });
    };

    return (
        <Chart
            {...rest}
            className="business-map"
            input={plotlyInput}
            tooltipIsInteractive
            getTooltipContent={getTooltipContent}
            hideTooltipArrow
            onHover={onHover}
            onUnhover={onUnhover}
        />
    );
};

(BusinessMap as DocumentedComponent).metadata = {
    description: "The `BusinessMap` is a high-level wrapper around a `Chart` component, with individual traces abstracted into various input fields.",
    isSelfClosing: true,
    attributes: [
        { name: `input`, type: `object`, description: "The input data for the business map." },
        { name: `layout`, type: `object`, description: "The optional layout overrides to apply to the chart.  The fields provided within this object will override the relevant ones from the default BSC theme." },
        {
            name: `onKpiDashboardClick`, type: `function`, template: `onKpiDashboardClick={(date) => {
    this.navigateToPage("$1", { $2 });
}}`, description: "The callback function to execute when the user clicks on the \"KPI Dashboard\" button.  The `navigateToPage` function will most likely be used here, to redirect the user to the KPI dashboard page, with the date argument passed in as a query parameter."
        },
        {
            name: `getMouseOverItems`,
            type: `function`,
            template: `getMouseOverItems={(nearestPoint, allPoints) => [
    {
        icon: $1,
        tooltipContent: $2,
        onClick: () => {
            $3
        }
    },
]}`,
            description: "The callback function used to calculate the available mouse-over items.  The `nearestPoint` argument is the actual point that's being hovered over, whereas the `allPoints` argument contains all the points with the same x-value as the current point.  `nearestPoint` can be used where tooltip information should be specific to a single point, whereas `allPoints` is useful in situations where a single \"x\" / \"x unified\" style tooltip is desired."
        },
    ]
};

export { BusinessMap };