import React from "react";
import ReactFlow, { Controls, Edge, MarkerType, Node, NodeChange, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from "reactflow";
import Dagre from "@dagrejs/dagre";

import "./FlowChart.scss";
import "reactflow/dist/style.css";
import { Chevron, Diamond, Group, MultiTriangle, Rectangle, RoundedRectangle, Triangle } from "./nodeTypes";
import { Bezier, SimpleBezier, SmoothStep, Step, Straight } from "./edgeTypes";
import { useResizeDetector } from "react-resize-detector";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

type HandlePosition = "top" | "bottom" | "left" | "right";

type FlowChartLink = {
    from?: string;
    to?: string;
    type?: string;
    color?: string;
    width?: number;
    className?: string;
    style?: any;
    animated?: boolean;
    markerStart?: MarkerType;
    markerEnd?: MarkerType;
    positionStart?: HandlePosition;
    positionEnd?: HandlePosition;

    label?: string;
    template?: string;
    content?: any;
};

type FlowChartNode = {
    id: string;
    type: string;
    hidden: boolean;
    links?: FlowChartLink[];
    children?: FlowChartNode[];
    className?: string;
    style?: any;

    label?: string;
    template?: string;
    content?: any;
};

type FlowChartProps = {
    nodes: FlowChartNode[];
    layoutOptions: {
        rankdir: "TB" | "BT" | "LR" | "RL";
        align: "UL" | "UR" | "DL" | "DR";
        ranker: "network-simplex" | "tight-tree" | "longest-path";
        nodesep: number;
        edgesep: number;
        ranksep: number;
    };
    getNodeContent: (node: FlowChartNode) => JSX.Element;
    getEdgeContent: (edge: FlowChartLink) => JSX.Element;
};

const nodeTypes = {
    "default": Rectangle,
    "chevron": Chevron,
    "diamond": Diamond,
    "group": Group,
    "multi-triangle": MultiTriangle,
    "rectangle": Rectangle,
    "rounded-rectangle": RoundedRectangle,
    "triangle": Triangle
};
const edgeTypes = {
    "default": Bezier,
    "bezier": Bezier,
    "straight": Straight,
    "step": Step,
    "smoothstep": SmoothStep,
    "simplebezier": SimpleBezier,
};

const proOptions = { hideAttribution: true };
const fitViewOptions = { padding: 0.05, duration: 0 };
const defaultLayoutOptions = {
    rankdir: "LR",
    align: null, // center
    ranker: "network-simplex",
    nodesep: 32,
    edgesep: 32,
    ranksep: 32
};
const defaultNodeOptions = {
    type: "process"
};
const defaultEdgeOptions = {
    type: "smoothstep",
    color: "var(--colors-grey-4)",
    width: 4,
    markerEnd: "arrowclosed" as MarkerType,
};

const includeEdgeInLayout = (edge: Edge<any>, nodes: Node<any, string>[]) => {
    const sourceNode = nodes.find(n => n.id === edge.source);
    const targetNode = nodes.find(n => n.id === edge.target);
    if (!sourceNode || !targetNode) {
        return false;
    }

    return sourceNode.parentId === targetNode.parentId;
}

const getEdgeHandles = (sourceNode: Dagre.Node<any>, targetNode: Dagre.Node<any>, edge: Edge<any>, useCurrentPosition = false, rankdir = "LR") => {
    if (!sourceNode || !targetNode) {
        return {};
    }

    const getPosition = (node) => useCurrentPosition ? node.position : { x: node.x, y: node.y };
    const sourcePosition = getPosition(sourceNode);
    const targetPosition = getPosition(targetNode);

    const xDirection = sourcePosition.x + sourceNode.width < targetPosition.x ? "right" : Math.abs(sourcePosition.x - targetPosition.x) < 40 ? "straight" : "left";
    const yDirection = sourcePosition.y + sourceNode.height < targetPosition.y ? "down" : Math.abs(sourcePosition.y - targetPosition.y) < 40 ? "straight" : "up";

    const sourceHandleMapping = {
        "right-down": "right",
        "right-up": "right",
        "left-down": "bottom",
        "left-up": "left",
        "straight-up": "top",
        "straight-down": "bottom"
    };

    const targetHandleMapping = {
        "right-down": "top",
        "right-up": "left",
        "left-down": "top",
        "left-up": "bottom",
        "straight-up": "bottom",
        "straight-down": "top"
    };

    const sourceHandle = `source-${edge.data.positionStart ?? sourceHandleMapping[`${xDirection}-${yDirection}`] ?? "right"}`;
    const targetHandle = `target-${edge.data.positionEnd ?? targetHandleMapping[`${xDirection}-${yDirection}`] ?? "left"}`;

    return { sourceHandle, targetHandle };
};

const getLayoutedElements = (nodes: Node<any, string>[], edges: Edge[], options: Dagre.GraphLabel) => {
    const g = new Dagre.graphlib.Graph({ directed: true, multigraph: true, compound: true }).setDefaultEdgeLabel(() => ({}));

    g.setGraph(options);

    nodes.forEach((node) => {
        g.setNode(node.id, node);
    });
    nodes.filter(n => n.parentId != null).forEach((node) => {
        g.setParent(node.id, node.parentId);
    });
    edges.filter(e => includeEdgeInLayout(e, nodes)).forEach((edge) => {
        g.setEdge(edge.source, edge.target);
    });

    Dagre.layout(g);

    const edgesWithHandles = edges.map((edge) => {
        const sourceNode = g.node(edge.source);
        const targetNode = g.node(edge.target);
        if (!sourceNode || !targetNode) {
            return edge;
        }

        const handles = getEdgeHandles(sourceNode, targetNode, edge, false, options.rankdir);
        return { ...edge, ...handles };
    });

    return {
        nodes: nodes.map((node) => {
            const position = g.node(node.id);
            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            let x = position.x - node.width / 2;
            let y = position.y - node.height / 2;

            // Position children relative to parent...
            if (node.parentId != null) {
                const parentNode = g.node(node.parentId);
                x -= parentNode.x - (parentNode.width / 2);
                y -= parentNode.y - (parentNode.height / 2) - 20; // Compensate for the 32px group heading + padding...
            }

            return {
                ...node, position: { x, y }, style: { ...node.style, width: node.width, height: node.height }
            };
        }),
        edges: edgesWithHandles,
    };
};

const mapInputData = (input: FlowChartNode[], getNodeContent: (node) => any, getEdgeContent: (edge) => any) => {
    const nodes: Node<any, string>[] = [];
    const edges: Edge<any>[] = [];

    function processNode(node: FlowChartNode, parent?: FlowChartNode) {
        // Add Node...
        const { id, type, hidden, links, children, className, style, ...data } = node;
        nodes.push({
            ...defaultNodeOptions,
            id,
            parentId: parent?.id,
            type: type ?? defaultNodeOptions.type,
            hidden,
            className,
            style,
            data: {
                ...data,
                id,
                parentId: parent?.id,
                type: type ?? defaultNodeOptions.type,
                getContent: getNodeContent
            },
            position: { x: 0, y: 0 }
        });

        // Add Edges...
        (links ?? []).forEach(link => {
            const { from, to, markerStart: _markerStart, markerEnd: _markerEnd, color: _color, width: _width, label, template, content, style, positionStart, positionEnd, ...rest } = link;
            const source = from ?? id;
            const target = to ?? id;
            const color = _color ?? defaultEdgeOptions.color;
            const width = _width ?? defaultEdgeOptions.width;

            const markerStart = _markerStart ? { type: _markerStart, color } : undefined;
            const markerEnd = { type: _markerEnd ?? defaultEdgeOptions.markerEnd, color };

            if (source === target) {
                return;
            }

            const edgeId = `${source}-${target}`;

            edges.push({
                ...defaultEdgeOptions,
                ...rest,
                id: edgeId,
                source,
                target,
                markerStart,
                markerEnd,
                style: { stroke: color, strokeWidth: width, ...style },
                data: {
                    id: edgeId,
                    source,
                    target,
                    label,
                    template,
                    content,
                    getContent: getEdgeContent,
                    className: rest.className,
                    positionStart,
                    positionEnd
                }
            });
        });

        // Add Children...
        (children ?? []).forEach(child => {
            processNode(child, node);
        });
    }

    input.forEach(node => processNode(node));

    return {
        nodes,
        edges
    };
};

const FLOW_CHART = (props: FlowChartProps) => {
    const { nodes: input, layoutOptions: _layoutOptions, getNodeContent, getEdgeContent, ...rest } = props;
    const layoutOptions = { ...defaultLayoutOptions, ..._layoutOptions };
    const { fitView } = useReactFlow();
    const [nodes, setNodes, _onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { width, height, ref } = useResizeDetector();

    const layoutElements = (nodes, edges) => {
        const layouted = getLayoutedElements(nodes, edges, layoutOptions);

        setNodes([...layouted.nodes]);
        setEdges([...layouted.edges]);
    };

    // The component needs to go through a few phases in order to render the flowchart correctly.
    // Since the rendering is done asynchronously in the browser via external JS code, there is no
    // way to know whether a phase has completed without "polling" the `nodes` value (which is mutated by the component).
    // Each state would therefore contain the current phase and the number of polling attempts made.  After a certain number
    // of attempts (10), we'll automatically move on to the next phase, in order to prevent infiniate loops.
    //
    // Phase 0 - Initial Render: wait for components to render and their dimensions to be populated
    // Phase 1 - Layout: wait for components to have valid x/y coordinates
    // Phase 2 - Fit View: apply scaling to the fit the chart within the current container
    // Phase 3 - Presentation: display the final result to the user
    const [renderState, setRenderState] = React.useState([0, 0]);
    React.useEffect(() => {
        const [phase, attempts] = renderState;
        // Give up on further refinement and just move on to the next phase...
        if (attempts > 10)
            setRenderState([phase + 1, 0]);

        switch (phase) {
            case 0: {
                const phaseCompleted = nodes.filter(n => !n.hidden).every(n => n.width !== undefined && n.height !== undefined);

                if (!phaseCompleted) {
                    setTimeout(() => setRenderState([0, attempts + 1]));
                    return;
                }

                layoutElements(nodes, edges);
                setRenderState([1, 0]);
                break;
            }
            case 1: {
                const phaseCompleted = nodes.filter(n => !n.hidden).every(n => n.position.x !== undefined && n.position.y !== undefined);

                if (!phaseCompleted) {
                    layoutElements(nodes, edges);
                    setTimeout(() => setRenderState([1, attempts + 1]));
                    return;
                }

                setRenderState([2, 0]);
                break;
            }
            case 2: {
                fitView(fitViewOptions);
                setRenderState([3, 0]);
                break;
            }
        }
    }, [renderState]);

    React.useEffect(() => {
        const { nodes, edges } = mapInputData(input, getNodeContent, getEdgeContent);
        setNodes(nodes);
        setEdges(edges);

        // Reset the state machine...
        setRenderState([0, 0]);
    }, [input, _layoutOptions]);

    const onNodesChange = (nodeChanges: NodeChange[]) => {
        _onNodesChange(nodeChanges);

        // Recalculate edge handles when a node is moved by the user...
        if (nodeChanges[0].type === "position" && !nodeChanges[0].dragging) {
            setTimeout(() => {
                const newEdges = edges.map(edge => {
                    const sourceNode = nodes.find(node => node.id === edge.source);
                    const targetNode = nodes.find(node => node.id === edge.target);
                    if (!sourceNode || !targetNode) {
                        return edge;
                    }

                    const handles = getEdgeHandles(sourceNode, targetNode, edge, true, layoutOptions.rankdir);
                    return { ...edge, ...handles };
                });

                setEdges(newEdges);
            }, 100);
        }
    };

    React.useEffect(() => {
        fitView(fitViewOptions);
    }, [width, height]);

    return (
        <div className="FlowChart-container" ref={ref} style={{ opacity: renderState[0] > 2 ? 1 : 0 }}>
            <ReactFlow
                fitViewOptions={fitViewOptions}
                defaultEdgeOptions={defaultEdgeOptions}
                nodesDraggable={false}
                nodesConnectable={false}
                elementsSelectable={false}
                minZoom={0.1}
                maxZoom={10}
                {...rest}
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                proOptions={proOptions}
            >
                <Controls showInteractive={false} fitViewOptions={fitViewOptions} />
            </ReactFlow>
        </div>
    );
};

function FlowChart(props: FlowChartProps) {
    return (
        <ReactFlowProvider>
            <FLOW_CHART {...props} />
        </ReactFlowProvider>
    );
}

(FlowChart as DocumentedComponent).metadata = {
    description: "This component is used to render process flow charts.  It is a wrapper around the [react-flow](https://reactflow.dev) library, and provides a more user-friendly interface for creating flow charts.",
    isSelfClosing: true,
    attributes: [
        {
            name: "nodes", type: "object", description: `The content to render in the chart.  See below for the definition of \`Node\`:

### \`Node\` props:

| Name | Type | Description |
|------|------|-------------|
| \`id\` | \`string\` | A unique identifier for the node.  Used primarily in link definitions, to define the from/to pairs. |
| \`type\` | \`string\` | The type of the node.  Determines which shape is rendered for this node.  Optional - defaults to \`rectangle\`.  Available options: \`chevron\`, \`diamond\`, \`group\`, \`multi-triangle\`, \`rectangle\`, \`rounded-rectangle\` and \`triangle\`. |
| \`className\` | \`string\` | Additional CSS classes to apply to the node.  Optional - defaults to an empty string. |
| \`hidden\` | \`boolean\` | If \`true\`, this node is not displayed to the end-user.  However, this node still contributes to the layout of the chart.  Useful in situations where uniform group sizes are desired or the layout needs simply needs some additional help.  Optional - defaults to \`false\`. |
| \`links\` | \`Link[]\` | The links which connect this node to other nodes.  Optional - defaults to an empty array.  See below for the definition of \`Link\`. |
| \`children\` | \`Node[]\` | The child nodes of this node.  Turns the current node into a group, containing the specified children.  Optional - defaults to an empty array. |
| \`label\` | \`string\` | The text to display in the node.  Optional - defaults to \`null\`. |
| \`content\` | \`JSX.Element\` | The content to display in the node.  Optional - defaults to \`null\`. |
| \`template\` | \`string\` | The template to use for this node.  This string is passed through to an embedded \`ContentRenderer\` component.  Optional - defaults to \`null\`. |
| \`fillPercentage\` | \`number\` | The percentage of vertical space to fill using the \`--fill-color\` value.  Optional - defaults to the entire node being filled. |


### \`Link\` props:

| Name | Type | Description |
|------|------|-------------|
| \`from\` | \`string\` | The id of the node from which this link originates.  If not specified, the \`id\` of the associated node is used, turning this into an outbound link. |
| \`to\` | \`string\` | The id of the node to which this link connects.  If not specified, the \`id\` of the associated node is used, turning this into an inbound link. |
| \`type\` | \`string\` | The type of the link.  Determines the path which this link takes.  Optional - defaults to \`smoothstep\`.  Possible values are \`step\`, \`smoothstep\`, \`bezier\`, \`simplebezier\` and \`straight\`.  |
| \`color\` | \`string\` | The color of the link.  Can be an RGB string, a named color or a CSS variable lookup.  Optional - defaults to dark grey. |
| \`width\` | \`number\` | The width of the link path (in pixels).  Optional - defaults to \`3\` pixels. |
| \`style\` | \`Object\` | Additional CSS styles rules to apply to the link.  Optional - defaults to an empty object. |
| \`animated\`| \`boolean\` | If \`true\`, the link is animated.  Optional - defaults to \`false\`. |
| \`markerStart\` | \`string\` | The marker to use at the start of the link.  Optional - defaults to \`null\`.  Possible values are \`arrow\` and \`arrowclosed\`. |
| \`markerEnd\` | \`string\` | The marker to use at the end of the link.  Optional - defaults to \`null\`.  Possible values are \`arrow\` and \`arrowclosed\`. |
| \`positionStart\` | \`"top" \| "right" \| "bottom" \| "left"\` | If provided, this forces a specific position for the start of the link (relative to the "from" node).  Optional - defaults to the position being calculated automatically.  Possible values are \`top\`, \`right\`, \`bottom\` and \`left\`. |
| \`positionEnd\` | \`"top" \| "right" \| "bottom" \| "left"\` | If provided, this forces a specific position for the end of the link (relative to the "to" node).  Optional - defaults to the position being calculated automatically.  Possible values are \`top\`, \`right\`, \`bottom\` and \`left\`. |
| \`label\` | \`string\` | The text to display on the link.  Optional - defaults to \`null\`. |
| \`content\` | \`JSX.Element\` | The content to display on the link.  Optional - defaults to \`null\`. |
| \`template\` | \`string\` | The template to use for this link.  This string is passed through to an embedded \`ContentRenderer\` component.  Optional - defaults to \`null\`. |
`},
        {
            name: "layoutOptions", type: "object", description: `The layout options to use when rendering the chart.  See below for the definition of \`LayoutOptions\`:

 ### \`LayoutOptions\` props:

| Name | Type | Description |
|------|------|-------------|
| \`rankdir\` | \`string\` | The direction in which the chart is laid out.  Optional - defaults to \`LR\`.  Possible values are \`TB\` (top-to-bottom), \`BT\` (bottom-to-top), \`LR\` (left-to-right) and \`RL\` (right-to-left). |
| \`ranker\` | \`string\` | The ranking algorithm used to determine the layout of the chart.  Optional - defaults to \`network - simplex\`.  Possible values are \`tight-tree\`, \`longest-path\` and \`network-simplex\`. |
| \`nodesep\` | \`number\` | The separation between nodes at the same level, which share the same parent (in pixels).  Optional - defaults to \`32\`. |
| \`edgesep\` | \`number\` | The separation between nodes at the same level, which do **not** share the same parent (in pixels).  Optional - defaults to \`32\`. |
| \`ranksep\` | \`number\` | The separation between levels of the chart (in pixels).  In a left-to-right layout, this   Optional - defaults to \`32\`. |
`},
        { name: "getNodeContent", type: "function", template: "getNodeContent={(node) => $1}", description: "A function which returns the content to render in a node.  Optional - defaults to rendering the first non-null \`content\`, \`template\` or \`label\` field of the node, in that order." },
        { name: "getEdgeContent", type: "function", template: "getEdgeContent={(edge) => $1}", description: "A function which returns the content to render in a link.  Optional - defaults to rendering the first non-null \`content\`, \`template\` or \`label\` field of the edge, in that order." },
    ]
};

export { FlowChart };