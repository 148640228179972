import * as React from "react";
import "./TreeView.scss";
import { TreeView as BaseTreeView } from "../../../shared/components";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

function getFullHierarchy(node: TreeViewNode): TreeViewNode[] {
    const allChildren = _.flatMap(node.children, getFullHierarchy);
    return [node, ...allChildren];
}

function containsSelectedNodes(selectedNodeValues: unknown[], currentNode: TreeViewNode): boolean {
    if (selectedNodeValues.find(nodeValue => _.isEqual(currentNode.value, nodeValue))) {
        return true;
    }
    if (!currentNode.children) {
        return false;
    }

    return !!currentNode.children.find(node => containsSelectedNodes(selectedNodeValues, node));
}

function addBackwardsCompatibility(node: TreeViewNode) {
    node.value = node.value ?? node["id"];
    node.label = node.label ?? node["title"];
}

export interface TreeViewNode {
    value?: any;
    label: string;
    children?: TreeViewNode[]
    isExpanded?: boolean;
    isSelected?: boolean;
    [key: string]: any;
}

interface TreeViewProps {
    nodes: TreeViewNode[];
    multiSelect?: boolean;
    onNodeSelect: (selectedNodes: TreeViewNode[]) => void;
    name?: string;
    parameterValues: Map<string, any>;
    showSearch?: boolean;
    renderNode?: (node: TreeViewNode, depth: number) => React.ReactNode;
}

function TreeView(props: TreeViewProps) {
    const { nodes, onNodeSelect, multiSelect, name, showSearch, ...rest } = props;
    const [expanded, setExpanded] = React.useState<unknown[]>([]);
    const [selected, setSelected] = React.useState<unknown[]>([]);
    const [highlighted, setHighlighted] = React.useState<unknown[]>([]);
    const [currentNodes, setCurrentNodes] = React.useState([]);
    const _parameterValue = useSelector((s: RootState) => s.storyline.parameterValues?.get?.(name));
    const parameterValue = _parameterValue ?? (multiSelect ? [] : null);
    const dispatch = useThunkDispatch();

    React.useEffect(() => {
        const allNodes = Array.isArray(nodes) ? _.flatMap(nodes, getFullHierarchy) : [];
        allNodes?.forEach?.(addBackwardsCompatibility);

        if (currentNodes?.length === nodes?.length && _.isEqual(currentNodes, nodes)) return;
        setCurrentNodes(nodes);

        let selected = multiSelect ? parameterValue : [parameterValue];

        const defaultExpandedNodeIds = allNodes.filter(n => n.isExpanded).map(n => n.value);
        const selectedExpandedNodeIds = allNodes.filter(n => containsSelectedNodes(selected, n)).map(n => n.value);
        setExpanded([...defaultExpandedNodeIds, ...selectedExpandedNodeIds]);

        const defaultSelectedNodeIds = allNodes.filter(n => n.isSelected).map(n => n.value);
        const allSelectedNodeIds = _.uniq([...selected, ...defaultSelectedNodeIds]);
        setSelected(allSelectedNodeIds);

        const highlightedNodes = allNodes.filter(n => containsSelectedNodes(allSelectedNodeIds, n)).map(n => n.value);
        setHighlighted(highlightedNodes);
    }, [nodes]);

    React.useEffect(() => {
        if (_parameterValue === undefined) return;

        const allNodes = Array.isArray(nodes) ? _.flatMap(nodes, getFullHierarchy) : [];
        let newSelected: any[] = multiSelect ? parameterValue : [parameterValue];
        if (selected?.length !== newSelected?.length || !_.isEqual(selected, newSelected)) {
            setSelected(newSelected);

            // Expand all the ancestors of the selected nodes...
            const expandedNodes = allNodes.filter(n => containsSelectedNodes(newSelected, n)).map(n => n.value);
            setExpanded([...expanded, ...expandedNodes]);
            setHighlighted([...expandedNodes]);
        }
    }, [_parameterValue]);

    const handleSelect = React.useCallback((selectedNodes: TreeViewNode[]) => {
        if (onNodeSelect) {
            onNodeSelect(selectedNodes);
        }

        if (name) {
            dispatch(updateParameterValue(name, multiSelect ? selectedNodes.map(n => n.value) : selectedNodes?.[0]?.value));
        }
    }, [onNodeSelect, name, multiSelect]);

    return (
        <BaseTreeView
            {...rest}
            defaultExpanded={expanded}
            defaultHighlighted={highlighted}
            defaultSelected={multiSelect ? selected : selected?.[0]}
            nodes={currentNodes}
            multiSelect={multiSelect}
            onSelectionChanged={handleSelect}
            showSearch={showSearch}
        />
    );
}

(TreeView as DocumentedComponent).metadata = {
    description: "The TreeView component renders hierarchical data in a collapsible tree structure.",
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the parameter to persist the selected Node Value(s) to.  If `multiselect` is enabled, the persisted value will be an array, otherwise it is a string value.  Optional - automated parameter read/write behaviour is disabled if the parameter name is not specified, but the `onNodeSelect` callback can still be used for this purpose." },
        { name: `nodes`, type: `object`, description: "The nodes to display in the tree.  See below for the structure of `TreeViewNode`." },
        { name: `multiSelect`, type: `boolean`, description: "If `true`, allows the user to select multiple items by holding down `Ctrl` or `Shift`.  Optional - defaults to `false`." },
        { name: `showSearch`, type: `boolean`, description: "If `true`, renders a text field that allows the user to search within the TreeView.  Optional - defaults to `false`." },
        { name: `onNodeSelect`, type: "function", template: `onNodeSelect={(selectedNodes) => {$1}}`, description: "The callback function to execute when a node is selected.  If `multiSelect` is `false`, the input parameter is a single-element array." },
        { name: `renderNode`, type: "function", template: `renderNode={(node, depth) => $1}`, description: "An optional callback function which can be used to customize the label for each node.  Invoked for each item in the tree, passing in the current item and its depth in the tree.  Optional - defaults to rendering the label (as a text element) for each item." },
        { name: `getNodeTooltip`, type: "function", template: `getNodeTooltip={(node) => {$1}}`, description: "The (optional) callback function used to provide tooltip content for each node.  If this function returns a `falsey` value, no tooltip will be shown for that particular node.  Optional - defaults to `null`." },
        { name: `getNodeSelectionDisabled`, type: "function", template: `getNodeSelectionDisabled={(node) => {$1}}`, description: "The (optional) callback function used to determine if a node is selectable.  If this function returns `true`, the node will not be selectable.  Optional - defaults to `false`." },
        { name: `maxSelectionCount`, type: "number", description: "The maximum number of nodes that can be selected.  Only applies when `multiSelect` is `true`.  Optional - defaults to unlimited." },
    ]
};

export { TreeView };