import * as React from "react";

export default class CanvasContentErrorBoundary extends React.Component<any, { error: any, errorInfo: any }> {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className="screen-centered">
                    <h2 id="home-header-2">Failed to Render Canvas.</h2>
                    <br />
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        <pre style={{ textAlign: "left" }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </pre>
                    </details>
                </div>
            );
        }

        // Normally, just render children...
        return this.props.children;
    }
}