export const DEFAULT_CHART_LAYOUT = {
    "title": {
        "font": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        }
    },
    "showlegend": true,
    "legend": {
        "bgcolor": "#ffffff",
        "bordercolor": "#4E4E4E",
        "borderwidth": 0,
        "font": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        },
        "itemclick": "toggle",
        "itemdoubleclick": "toggleothers",
        "orientation": "v",
        "title": {
            "font": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            }
        },
        "x": 1,
        "xanchor": "left",
        "y": 1,
        "yanchor": "top"
    },
    "margin": {
        "autoexpand": true,
        "b": 5,
        "l": 5,
        "t": 5,
        "r": 5
    },
    "autosize": true,
    "font": {
        "size": 12,
        "color": "#2C2C2C",
        "family": "Helvetica Neue"
    },
    "separators": ",",
    "paper_bgcolor": "rgba(255, 255, 255, 0.0)",
    "plot_bgcolor": "rgba(255, 255, 255, 0.0)",
    "xaxis": {
        "automargin": true,
        "autorange": true,
        "tickangle": 0,
        "tickcolor": "#CDCDCD",
        "tickfont": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        },
        "tickformat": "",
        "zeroline": false,
        "zerolinecolor": "#CDCDCD",
        "zerolinewidth": 2,
        "showgrid": false,
        "title": {
            "font": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            }
        }
    },
    "yaxis": {
        "automargin": true,
        "autorange": true,
        "tickangle": 0,
        "tickcolor": "#CDCDCD",
        "tickfont": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        },
        "tickformat": "",
        "zeroline": false,
        "zerolinecolor": "#CDCDCD",
        "zerolinewidth": 2,
        "showgrid": false,
        "title": {
            "font": {
                "size": 12,
                "color": "#2C2C2C",
                "family": "Helvetica Neue"
            }
        }
    },
    "hoverlabel": {
        "font": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        },
        "grouptitlefont": {
            "size": 12,
            "color": "#2C2C2C",
            "family": "Helvetica Neue"
        },
        "align": "auto",
        "bgcolor": "#ffffff",
        "bordercolor": "#4E4E4E",
        "borderwidth": 0,
        "namelength": -1
    }
};