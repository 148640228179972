import * as React from "react";
import "./TreeView.scss";
import { TreeView as MaterialTreeView, TreeItem as MaterialTreeItem, TreeViewProps as MaterialTreeViewProps } from "@mui/lab";
import { OutlinedInput, InputAdornment } from "./";
import { TriangleRight, TriangleDown, Search } from "./icons";
import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { tap, debounceTime } from 'rxjs/operators';
import clsx from "clsx";
import { Tooltip } from "../../viewer/components/Tooltip";
import { structuralEqualityExcludingTransientEntries } from "../utilities";

function filterItems(node: TreeViewNode, searchTerm: string) {
    // Search term is blank or this node matches the search criteria...
    if (!searchTerm || node.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        return node;
    }

    const survivors = _.chain(node.children).map(a => filterItems(a, searchTerm)).filter(a => !!a).value();

    if (survivors?.length > 0) {
        return {
            ...node,
            children: survivors
        };
    } else {
        return null;
    }
}

function highlightSearchTerm(text: string, searchTerm: string) {
    const startIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (startIndex === -1) return <span>{text}</span>;

    const endIndex = startIndex + searchTerm.length;
    return <span>
        {text.slice(0, startIndex)}
        <mark>{text.slice(startIndex, endIndex)}</mark>
        {text.slice(endIndex, text.length)}
    </span>;
}

function getFullHierarchy(node: TreeViewNode): TreeViewNode[] {
    const allChildren = _.flatMap(node.children, getFullHierarchy);
    return [node, ...allChildren];
}

function containsSelectedNodes(selectedNodeValues: string[], currentNode: TreeViewNode): boolean {
    if (!currentNode.children) {
        return false;
    }

    if (currentNode.children.some(c => selectedNodeValues.includes(c.value))) {
        return true;
    }

    return !!currentNode.children.find(node => containsSelectedNodes(selectedNodeValues, node));
}

// It gets complicated when you try to trim the branches midway, so instead we just cap the number of leaf nodes in the tree when rendering the search results.
function takeNLeafNodesFromTree(tree: TreeViewNode[], n: number) {
    let remainder = n;

    const getPrunedMenuItem = (item: TreeViewNode) => {
        if (!item.children?.length) {
            if (remainder > 0) {
                remainder--;
                return item;
            }
            else {
                return null;
            }
        }
        else {
            let trimmedChildren = item.children?.map(getPrunedMenuItem).filter(a => !!a);
            if (!trimmedChildren.length) {
                return null;
            }
            else {
                return { ...item, children: trimmedChildren };
            }
        }
    }

    return tree.map(getPrunedMenuItem).filter(a => !!a);
}

function hasAncestorWithClassName(element: HTMLElement, className: string) {
    if (!element) return false;
    if (element.classList.contains(className)) return true;

    return hasAncestorWithClassName(element.parentElement, className);
}

export interface TreeViewNode {
    value?: any;
    label: string;
    children?: TreeViewNode[];
    preventSelection?: boolean;
    [key: string]: any;
}

type PrivateTreeViewProps = MaterialTreeViewProps & {
    nodes: TreeViewNode[];
    multiSelect?: boolean;
    onSelectionChanged?: (selectedNodes: TreeViewNode[]) => void;
    showSearch?: boolean;
    defaultSearchTerm?: string;
    defaultHighlighted?: string[];
    defaultExpanded?: string[];
    multiSelectWithoutCtrl?: boolean;
    renderNode?: (item: TreeViewNode, depth: number) => React.ReactNode;
    getNodeSelectionDisabled?: (node: TreeViewNode) => boolean;
    maxSelectionCount?: number;
    getNodeTooltip?: (node: TreeViewNode) => any;
}

function TREE_VIEW(props: PrivateTreeViewProps) {
    const { nodes, onSelectionChanged, multiSelect, showSearch, defaultSearchTerm, defaultExpanded, defaultSelected, defaultHighlighted, multiSelectWithoutCtrl, renderNode: customRenderNode, getNodeTooltip: _getNodeTooltip, getNodeSelectionDisabled, maxSelectionCount, ...rest } = props;
    const [expanded, setExpanded] = React.useState<string[]>(defaultExpanded ?? []);
    const [selected, setSelected] = React.useState<string | string[]>(defaultSelected ?? []);
    const [highlighted, setHighlighted] = React.useState<string[]>(defaultHighlighted ?? []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchTermSubject$, _setSearchTermSubject$] = React.useState(new BehaviorSubject<string>(""));
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermText, setSearchTermText] = React.useState("");
    const [filteredItems, setFilteredItems] = React.useState(nodes ?? []);
    const [expandedSearchNodes, setExpandedSearchNodes] = React.useState([]);

    const getNodeTooltip = (node: TreeViewNode) => {
        if (multiSelect && maxSelectionCount && selected?.length >= maxSelectionCount && !(selected as string[]).find(s => s === node.value)) {
            return `A maximum of ${maxSelectionCount} items can be selected.`;
        }

        return _getNodeTooltip?.(node);
    };

    const renderNode = React.useCallback((item: TreeViewNode, depth: number = 1) => {
        return (
            <MaterialTreeItem
                key={item.key ?? item.value}
                nodeId={item.value}
                label={
                    <NodeTooltip getNodeTooltip={getNodeTooltip} node={item}>
                        {
                            searchTerm ? highlightSearchTerm(item.label, searchTerm) :
                                customRenderNode ? customRenderNode(item, depth) :
                                    item.label
                        }
                    </NodeTooltip>
                }
                className={clsx(highlighted.includes(item.value) && "active", item.preventSelection && "not-selectable")}
            >
                {
                    Array.isArray(item.children) ?
                        item.children.map((node) => renderNode(node, depth + 1)) :
                        null
                }
            </MaterialTreeItem>
        )
    }, [onSelectionChanged, highlighted, searchTerm]);

    React.useEffect(() => {
        const searchTermSubscription = searchTermSubject$
            .pipe(
                tap(setSearchTermText), // Immediately update the text in the input control...
                debounceTime(500)       // And debounce the update of the search term...
            ).subscribe(setSearchTerm);

        return () => searchTermSubscription.unsubscribe();
    }, []);

    React.useEffect(() => {
        if (_.isEqual(searchTerm, defaultSearchTerm)) return;

        searchTermSubject$.next(defaultSearchTerm);
    }, [defaultSearchTerm]);

    React.useEffect(() => {
        if (_.isEqual(expanded, (defaultExpanded ?? []))) return;

        setExpanded(defaultExpanded);
    }, [defaultExpanded]);

    React.useEffect(() => {
        if (_.isEqual(highlighted, (defaultHighlighted ?? []))) return;

        setHighlighted(defaultHighlighted);
    }, [defaultHighlighted]);

    React.useEffect(() => {
        if (_.isEqual(selected, (defaultSelected ?? []))) return;

        setSelected(defaultSelected);

        // Highlight and expand parent nodes of selected nodes...
        const allNodes = _.flatMap(nodes, getFullHierarchy);
        const normalizedNodeIds = multiSelect ? defaultSelected : [defaultSelected];
        const selectedNodes = normalizedNodeIds.map(nodeId => allNodes.find(n => _.isEqual(nodeId, n.value))).filter(v => !!v);
        const selectedNodeValues = selectedNodes.map(sn => sn.value);
        const highlightedNodes = allNodes.filter(n => selectedNodeValues.includes(n.value) || containsSelectedNodes(selectedNodeValues, n)).map(n => n.value).filter(v => !!v);
        setHighlighted(highlightedNodes);
        const parentsWithSelectedChildren = allNodes.filter(n => containsSelectedNodes(selectedNodeValues, n)).map(n => n.value).filter(v => !!v);
        setExpanded(_.uniq([...expanded, ...parentsWithSelectedChildren]));
    }, [defaultSelected]);


    React.useEffect(() => {
        if (searchTerm) {
            let filteredResults = _.chain(nodes).map(a => filterItems(a, searchTerm)).filter(a => !!a).value();
            filteredResults = takeNLeafNodesFromTree(filteredResults, 200); // Cap the search results at 200 leaf nodes, for performance reasons...
            setFilteredItems(filteredResults);
            setExpandedSearchNodes(_.chain(filteredResults).flatMapDeep(getFullHierarchy).filter(mi => mi?.children?.length > 0).map(mi => mi.value).value());
        }
        else {
            setFilteredItems(nodes);
            setExpandedSearchNodes([]);
        }
    }, [nodes, searchTerm]);

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        // Don't treat label clicks as collapse/expand events...
        if (!hasAncestorWithClassName(event?.target as HTMLElement, "MuiTreeItem-iconContainer")) return;

        setExpanded(nodeIds);
    };

    const handleSelect = React.useCallback((event: React.SyntheticEvent, nodeIds: string | string[]) => {
        // Don't treat collapse/expand button clicks as selection...
        if (hasAncestorWithClassName(event?.target as HTMLElement, "MuiTreeItem-iconContainer")) return;

        // Workaround for scenarios where we don't want the user to explicitly use Ctrl when multi-selecting...
        if (multiSelect && multiSelectWithoutCtrl && nodeIds.length <= 1) {
            const toggledNodeId = nodeIds?.[0];
            if ((selected as string[]).find(s => s === toggledNodeId)) {
                nodeIds = (selected as string[]).filter(s => s !== toggledNodeId);
            } else {
                nodeIds = [...selected, toggledNodeId];
            }
        }

        const allNodes = _.flatMap(nodes, getFullHierarchy);
        const normalizedNodeIds = _.isArray(nodeIds) ? nodeIds : [nodeIds];
        const selectedNodes = normalizedNodeIds.map(nodeId => allNodes.find(n => _.isEqual(nodeId, n.value))).filter(v => !!v);

        // User has toggled/clicked on a node that prevents selection or the max selection count has been exceeded...
        if (selectedNodes.some(n => (n.preventSelection || getNodeSelectionDisabled?.(n) || (maxSelectionCount && normalizedNodeIds.length > maxSelectionCount)))) {
            return;
        }

        setSelected(nodeIds);
        const selectedNodeValues = selectedNodes.map(sn => sn.value);
        const highlightedNodes = allNodes.filter(n => selectedNodeValues.includes(n.value) || containsSelectedNodes(selectedNodeValues, n)).map(n => n.value).filter(v => !!v);
        setHighlighted(highlightedNodes);
        const parentsWithSelectedChildren = allNodes.filter(n => containsSelectedNodes(selectedNodeValues, n)).map(n => n.value).filter(v => !!v);
        setExpanded(_.uniq([...expanded, ...parentsWithSelectedChildren]));

        if (onSelectionChanged) {
            onSelectionChanged(selectedNodes);
        }

    }, [onSelectionChanged, nodes, selected, getNodeSelectionDisabled, expanded]);

    return (
        <div className="treeview-component-container">
            {
                showSearch &&
                <div className="search-row">
                    <OutlinedInput
                        size="small"
                        placeholder="Search"
                        className="search-menu-items-input"
                        fullWidth
                        startAdornment={
                            <InputAdornment position="start">
                                <Search size="small" />
                            </InputAdornment>
                        }
                        value={searchTermText}
                        onChange={(event) => searchTermSubject$.next(event.target.value)}
                    />
                </div>
            }
            {
                filteredItems?.length > 0 ?
                    <MaterialTreeView
                        expanded={searchTerm ? expandedSearchNodes : expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        defaultCollapseIcon={<TriangleDown size="medium" />}
                        defaultExpandIcon={<TriangleRight size="medium" />}
                        multiSelect={multiSelect ? true : null}
                        {...(rest as any)}
                    >
                        {filteredItems.map(i => renderNode(i, 1))}
                    </MaterialTreeView> :
                    <div className="no-items">No options available.</div>
            }

        </div>
    );
}

type TreeViewProps = PrivateTreeViewProps & {
    defaultSelected?: unknown | unknown[];
    defaultHighlighted?: unknown[];
    defaultExpanded?: unknown[];
};

/// Wrapper component to allow for using non-string node values.  All node values are serialized to JSON and reversed again in the callback handlers...
function _TreeView(props: TreeViewProps) {
    const { multiSelect, nodes: _nodes, defaultSelected: _defaultSelected, defaultHighlighted: _defaultHighlighted, defaultExpanded: _defaultExpanded, onSelectionChanged: _onSelectionChanged, getNodeSelectionDisabled: _getNodeSelectionDisabled, ...rest } = props;

    function mapNode(node: TreeViewNode): TreeViewNode {
        return {
            ...node,
            value: JSON.stringify(node.value),
            children: (node.children ?? []).map(mapNode)
        };
    };

    const nodes = (_nodes ?? []).map(mapNode);

    const defaultSelected = _defaultSelected ?
        multiSelect ?
            (_defaultSelected as unknown[]).map(x => JSON.stringify(x)) :
            JSON.stringify(_defaultSelected) :
        undefined;

    const defaultHighlighted = _defaultHighlighted ?
        (_defaultHighlighted as unknown[]).map(x => JSON.stringify(x)) :
        undefined;

    const defaultExpanded = _defaultExpanded ?
        (_defaultExpanded as unknown[]).map(x => JSON.stringify(x)) :
        undefined;

    const onSelectionChanged = React.useCallback((selectedNodes: TreeViewNode[]) =>
        _onSelectionChanged?.(selectedNodes.map(n => ({ ...n, value: JSON.parse(n.value) }))),
        [_onSelectionChanged]
    );

    const getNodeSelectionDisabled = React.useCallback((node: TreeViewNode) =>
        _getNodeSelectionDisabled?.({ ...node, value: JSON.parse(node.value) }),
        [_getNodeSelectionDisabled]
    );

    return (
        <TREE_VIEW
            multiSelect={multiSelect}
            nodes={nodes}
            defaultSelected={defaultSelected}
            defaultHighlighted={defaultHighlighted}
            defaultExpanded={defaultExpanded}
            onSelectionChanged={onSelectionChanged}
            getNodeSelectionDisabled={getNodeSelectionDisabled}
            {...rest}
        />
    );
}

const TreeView = React.memo(_TreeView, (prevProps, nextProps) => structuralEqualityExcludingTransientEntries(prevProps, nextProps));

type NodeTooltipProps = {
    node: TreeViewNode;
    getNodeTooltip: (node: TreeViewNode) => any;
    children: any;
};

function NodeTooltip(props: NodeTooltipProps) {
    const { node, getNodeTooltip, children } = props;
    if (!getNodeTooltip) return <>{children}</>;

    const tooltipContent = getNodeTooltip(node);
    if (!tooltipContent) return <>{children}</>;

    return (
        <Tooltip
            arrow
            placement="left"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 24],
                            },
                        },
                    ],
                },
            }}
            title={tooltipContent}
        >
            <div className="col-fill">
                {children}
            </div>
        </Tooltip>
    );
}

export { TreeView };