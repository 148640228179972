import { getUserPreferences } from "../../shared/services/userPreferencesService";

type animationDirection = "left" | "up" | "right" | "down";

function animateSlideTransition(keyframes: Keyframe[], useOldSlideState: boolean = false, transformOrigin: string = "center", zIndex = 1) {
    const duration = 1000;
    const container = document.querySelector(".storyline > .main-canvas");

    if (container) {
        if (useOldSlideState) {
            const clone = container.cloneNode(true);
            (clone as Element).setAttribute("style", `position: absolute; left: 0; top: 0; right: 0; height: ${container.clientHeight}px; transform-origin: ${transformOrigin}; z-index: ${zIndex}`);
            container.parentElement.appendChild(clone);

            (clone as Element).classList.toggle("animate");
            clone["animate"](keyframes, {
                duration: duration,
                easing: "cubic-bezier(0.45, 0, 0.55, 1)",
                fill: "forwards"
            });
            setTimeout(() => {
                container.parentElement.removeChild(clone);
            }, 2500);
        }
        else {
            container.setAttribute("style", `transform-origin: ${transformOrigin}; z-index: ${zIndex};`);
            container.animate(keyframes, {
                duration: duration,
                easing: "cubic-bezier(0.45, 0, 0.55, 1)",
                fill: "forwards"
            });
        }
    }
}

export function animateSlideNavigation(direction: animationDirection) {
    const animationStyle = getUserPreferences().slideTransitionAnimationStyle || "slide";

    try {
        switch (animationStyle) {
            case "none":
                return;
            case "slide":
                doSlideAnimation(direction);
                return;
            case "flip":
                doFlipAnimation(direction)
                return;
        }
    }
    catch (ex) {
        // Browser doesn't support animation API, canvas has been deleted, etc.  Just log the error to console and carry on...
        console.log(ex);
    }
}

function doSlideAnimation(direction: animationDirection) {
    switch (direction) {
        case "left":
            animateSlideTransition([
                { transform: 'translateX(0%)' },
                { transform: 'translateX(100%)' }
            ], true);

            animateSlideTransition([
                { transform: 'translateX(-100%)' },
                { transform: 'translateX(0%)' }
            ], false);

            return;
        case "right":
            animateSlideTransition([
                { transform: 'translateX(0%)' },
                { transform: 'translateX(-100%)' }
            ], true);

            animateSlideTransition([
                { transform: 'translateX(100%)' },
                { transform: 'translateX(0%)' }
            ], false);

            return;
        case "up":
            animateSlideTransition([
                { transform: 'translateY(0%)' },
                { transform: 'translateY(100%)' }
            ], true);

            animateSlideTransition([
                { transform: 'translateY(-100%)' },
                { transform: 'translateY(0%)' }
            ], false);

            return;
        case "down":
            animateSlideTransition([
                { transform: 'translateY(0%)' },
                { transform: 'translateY(-100%)' }
            ], true);

            animateSlideTransition([
                { transform: 'translateY(100%)' },
                { transform: 'translateY(0%)' }
            ], false);

            return;
    }
}

function doFlipAnimation(direction: animationDirection) {
    switch (direction) {
        case "left":
        case "up":
            animateSlideTransition([
                { transform: 'translateX(0%)' },
                { transform: 'translateX(0%)' }
            ], true, "center", 1);

            animateSlideTransition([
                { transform: 'rotate3d(-1, 1, 0, 90deg)' },
                { transform: 'rotate3d(-1, 1, 0, 0deg)' }
            ], false, "0% 0%", 2);

            return;

        case "right":
        case "down":
            animateSlideTransition([
                { transform: 'translateX(0%)' },
                { transform: 'translateX(0%)' }
            ], false, "center", 1);

            animateSlideTransition([
                { transform: 'rotate3d(-1, 1, 0, 0deg)' },
                { transform: 'rotate3d(-1, 1, 0, 90deg)' }
            ], true, "0% 0%", 2);

            return;
    }
}