import "./Template.scss";
import * as React from "react";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import JsxParser from "@recursive-robot/react-jsx-parser";
import Style from 'style-it';
import { useCanvasBindings } from "../../../shared/providers/CanvasBindingsProvider";
import { useJsxParserComponents } from "../../../shared/providers/JsxParserComponentsProvider";
import clsx from "clsx";
import { useSelector } from "react-redux";
import type { RootState } from "../../../store";
import { sendTemplateBindingsToVsCode } from "../../../store/vscode/actions";

const blacklistedAttrs = [];
const blacklistedTags = [];
const renderError = ({ error }) => <span className="error-message">{`JSX error: ${error}`}</span>;

export type TemplateProps = {
    // Pass in the path to the template object in the storyline...
    path?: string;
    // OR pass in the JSX and CSS directly...
    jsx?: string;
    css?: string;
    // Addtional props are used as (scoped) bindings for the template...
    [key: string]: any;
}

function _Template(props: TemplateProps) {
    const { path, jsx, css, children, className, ...scopedInputs } = props;
    const _bindings = useCanvasBindings();
    const components = useJsxParserComponents();
    const templates = useSelector((state: RootState) => state.storyline.storyline.templates);
    const template = templates[path] ?? { jsx, css };

    const defineFunction = React.useCallback((...args) => {
        return new Function(...args).bind({ ..._bindings, ...scopedInputs }); // eslint-disable-line no-new-func
    }, [_bindings]);

    const bindings = { ..._bindings, ...scopedInputs, defineFunction, children };

    const result = (
        <JsxParser
            bindings={bindings}
            components={components}
            jsx={template.jsx}
            disableKeyGeneration={true}
            blacklistedTags={blacklistedTags}
            blacklistedAttrs={blacklistedAttrs}
            renderError={renderError}
            renderInWrapper={false}
            key={path}
        />
    );

    // Send the bindings to the VS Code extension, to provide Intellisense for scoped input expressions...
    sendTemplateBindingsToVsCode(path, scopedInputs);

    return template.css ?
        Style.it(template.css, <div className={clsx(className, "template-fragment")}>{result}</div>) :
        result;
}

const Template = React.memo(_Template);

(Template as DocumentedComponent).metadata = {
    description: "Parses and renders the contents of the referenced `jsx` file.  Any additional props passed in will be made available to the bindings within this template.",
    attributes: [
        { name: "path", type: "string", description: "The relative path to the template file to render.  `{path}.jsx`, `{path}.css` and `{path}.json` are used for the JSX, CSS and documentation respectively." },
        { name: "className", type: "string", description: "The (optional) class name(s) to apply to the container div." }
    ],
    isSelfClosing: true // Even though the Template component can take in children (to act as a wrapper), this is probably the exception rather than the rule...
};

export { Template };