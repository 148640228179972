import React from "react";
import Joyride, { STATUS, CallBackProps, Props as JoyrideProps } from "react-joyride";
import useLocalStorage from "../shared/hooks/useLocalStorage";
import * as _ from "lodash";

export interface RunOnceTourProps extends JoyrideProps {
    id: string;
}

export default function RunOnceTour(props: RunOnceTourProps) {
    const [hasCompletedTour, setHasCompletedTour] = useLocalStorage(`completed-tour-${props.id || new Date().toISOString()}`, false);

    // This callback just watches for the tour end and records that in local storage.  It wraps over
    // the original callback passed in, adding to its logic...
    const callbackWrapper = (data: CallBackProps) => {
        const { status } = data;

        // Call the original callback that was passed in...
        props.callback && props.callback(data);

        // Mark the tour as completed when applicable...
        if (_.includes([STATUS.FINISHED, STATUS.SKIPPED], status)) {
            setHasCompletedTour(true);
        }
    };

    return !hasCompletedTour ?
        <Joyride
            {...props}
            callback={callbackWrapper}
        /> :
        null;
}