export interface NotificationsState {
    notifications: any[];
    outstandingRequestCount: number;
}

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const INCREMENT_OUTSTANDING_REQUEST_COUNT = "INCREMENT_OUTSTANDING_REQUEST_COUNT";
export const DECREMENT_OUTSTANDING_REQUEST_COUNT = "DECREMENT_OUTSTANDING_REQUEST_COUNT";

interface EnqueueSnackbarAction {
    type: typeof ENQUEUE_SNACKBAR,
    notification: any
}

interface CloseSnackbarAction {
    type: typeof CLOSE_SNACKBAR,
    dismissAll: Boolean,
    key: string
}

interface RemoveSnackbarAction {
    type: typeof REMOVE_SNACKBAR,
    key: string
}

interface IncrementOutstandingRequestCountAction {
    type: typeof INCREMENT_OUTSTANDING_REQUEST_COUNT
}

interface DecrementOutstandingRequestCountAction {
    type: typeof DECREMENT_OUTSTANDING_REQUEST_COUNT
}

export type NotificationActionTypes = EnqueueSnackbarAction | CloseSnackbarAction | RemoveSnackbarAction | IncrementOutstandingRequestCountAction | DecrementOutstandingRequestCountAction;